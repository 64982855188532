.healthcare-title > *,
.healthcare-page-title > * {
  color: #fff;
}

// .flex-row-container {
//   //background: #aaa;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
// }
// .flex-row-container > .flex-row-item {
//   width: 33.33%;
//   padding: 30px;
//   text-align: -webkit-center;
//   background: center;
// }

// @media (min-width: 768px) {
//   .threeColimg .squareGridblocks .squareContent {
//     padding: 20% 0.9375rem;
//     max-width: 25.75rem;
//     background-image: none !important;
//   }
// }
//new code css

.row {
  display: flex;
  //flex: 1 1 100%;
  // padding: 25px 0;
  // margin: 10px auto;
}
.w60 {
  flex: 1 0 60%;
  background: #f9f9f9;
  //padding: 10px 20px;
}
.textbox-1 {
  font-size: 1.05em;
  text-align: justify;
  //min-height: 100px;
  display: flex;
}
.inner1 {
  margin: auto;
  max-width: 20rem;
  text-align: center;
}

// .object-fit {
//   overflow: hidden;
//   position: relative;
// }
.w30 {
  //background: #000;
  flex: 33.33%;
  //padding: 20% 0.9375rem;
}
.object-fit img {
  //position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: none;
  object-fit: cover;
}

.software-container li {
  list-style-type: square;
  font-size: 16px !important;
  line-height: 28px !important;
}

.software-container {
  display: flex;
  width: 100%;
}

.column-1 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 50%;
}

.box {
  padding: 10px;
  border-radius: 12px;
  margin: 1rem;
}

@media only screen and (max-width: 900px) {
  .software-container {
    flex-direction: column;
  }

  .box {
    margin: 0 0 1rem;
  }
}
// .image-over-columns {
//   position: relative;
// }
// .image1 {
//   position: absolute;
//   bottom: 0; /* i deleted top:0; */
//   left: 50%; /* this and below code for excellent centered */
//   transform: translateX(-50%);
//   z-index: 2;
// }
.right-section h3 {
  color: #fff;
}
.section-title p {
  font-size: 22px;
}

.new-tech{
 // background-size: 100% auto;
     background-position: top -1.1875rem left 0;
    // background-position: center;
  position: relative;
  overflow: hidden;
  text-align: center;
  
  //background-position: center;
}


// @media screen and (min-width: 990px) {
//   .new-tech{
//     background-size: 50% auto;
//     background-size: cover;
//   }
// }
// @media screen and (max-width: 991px) and (min-width: 720px) {
//   .new-tech {
//       //background-size: 100% auto;
//       //background-position: bottom -2.1875rem left 0;
//   }
// }
@media screen and (max-width: 720px) {
  .new-tech {
    background-size: 100% auto;
    background: no-repeat;
    overflow: hidden;
    background-size: cover;
    background-position: center ;
  }
}

.travelFont{
  padding-bottom: 1rem;
    font-weight: 600;
    font-size: 1.7rem;
}
@media (min-width: 768px){
  .dark{
    font-size: 2.125rem;
    line-height: 2.875rem;
  }
}
@media ((min-width: 576px)){
  .dark{
    font-size: 2.125rem;
    line-height: 2.875rem;
  }
}
@media ((min-width: 460px)){
  .dark{
    font-size: 1.875rem;
    line-height: 2.625rem;
  }
}
@media ((min-width: 360px)){
  .dark{
    font-size: 1.6875rem;
    line-height: 2.3125rem;
  }
}


.h3{
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding-bottom: 0.625rem;
  color: #fff;

}
.squrecontent-second, .squrecontent-second h3 {
  color: #fff;
  
}
.squrecontent-second{
  background-color:#2aacbb;

}
.squrecontent-second:hover{
  background-color: navy
}
// .squrecontent-second h3 { 
//   font-size: 2rem;
// }

@media (min-width: 1371px){
  .squrecontent-second {
      padding: 5vw 1.25rem;
  }
}
@media (min-width: 992px){
  .squrecontent-second {
    padding: 3vw 1.25rem;
  }
}
@media (min-width: 576px){
  .squrecontent-second {
    margin-top: 0;
    padding: 1.875rem 1.25rem;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
}

.grid-container > div {
  padding: 20px 0;
  font-size: 30px;
}

.item1 {
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
}
.title{
  font-weight: 600;
}

.relative{
  position: relative;
}

.left-line>div:last-child::after {
  content: "";
  width: 6.25rem;
  height: 0.1875rem;
  position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    background-color: rgba(49,187,174,1);
    
}
.left-line>div:last-child{
  padding-bottom: 1.8rem
}